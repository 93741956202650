var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-section",attrs:{"id":"details-section"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"thumbnail",style:({
          'background-image': `url('${_vm.giftCard.thumbnail}')`,
          'background-size': 'contain',
        })})]),_c('div',{staticClass:"col-md-6"},[_c('h4',[_vm._v("Redeem your "+_vm._s(_vm.team.points_name))]),(_vm.giftCard.cost == 'VARIABLE_VALUE')?[_c('div',{staticClass:"form-group"},[_c('div',{class:[
              'input-group',
              'input-group-lg',
              { 'is-invalid': !_vm.allow_redemption },
            ]},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.currency_symbol))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currency_value),expression:"currency_value"}],class:['form-control', { 'is-invalid': !_vm.allow_redemption }],attrs:{"type":"text"},domProps:{"value":(_vm.currency_value)},on:{"input":function($event){if($event.target.composing)return;_vm.currency_value=$event.target.value}}})]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.warning))])])]:[_c('div',{class:['form-group', { 'is-invalid': !_vm.allow_redemption }]},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.item_id),expression:"item_id"}],staticClass:"form-control form-control-lg",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.item_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.giftCard.variants),function(item){return _c('option',{key:`gc_variant_${item.utid}`,domProps:{"value":item.utid}},[_vm._v(" "+_vm._s(_vm.$formatCurrency(item.faceValue, item.currencyCode))+" Gift Card for "+_vm._s(_vm.$formatPoints( _vm.calculatePoints(item.faceValue, item.currencyCode) ))+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.warning))])])],_c('button',{class:['btn', 'btn-block', 'btn-green', { loading: _vm.loading }],attrs:{"disabled":!_vm.allow_redemption},on:{"click":_vm.redeemGiftCard}},[_vm._v(" Redeem for "),_c('strong',[_vm._v(_vm._s(_vm.$formatPoints(_vm.point_value)))])])],2)]),_c('div',{staticClass:"row description-row"},[_c('div',{staticClass:"col-md-9",domProps:{"innerHTML":_vm._s(_vm.giftCard.description)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }