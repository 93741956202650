<template>
  <section class="main-section" id="details-section">
    <div class="row align-items-center">
      <div class="col-md-4">
        <div
          class="thumbnail"
          :style="{
            'background-image': `url('${giftCard.thumbnail}')`,
            'background-size': 'contain',
          }"
        ></div>
      </div>
      <div class="col-md-6">
        <h4>Redeem your {{ team.points_name }}</h4>
        <template v-if="giftCard.cost == 'VARIABLE_VALUE'">
          <!-- Variable value -->
          <div class="form-group">
            <div
              :class="[
                'input-group',
                'input-group-lg',
                { 'is-invalid': !allow_redemption },
              ]"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">{{ currency_symbol }}</span>
              </div>
              <input
                type="text"
                v-model="currency_value"
                :class="['form-control', { 'is-invalid': !allow_redemption }]"
              />
            </div>
            <div class="invalid-feedback">{{ warning }}</div>
          </div>
        </template>

        <template v-else>
          <!-- Fixed value variants -->
          <div :class="['form-group', { 'is-invalid': !allow_redemption }]">
            <select class="form-control form-control-lg" v-model="item_id">
              <option
                v-for="item in giftCard.variants"
                v-bind:value="item.utid"
                v-bind:key="`gc_variant_${item.utid}`"
              >
                {{ $formatCurrency(item.faceValue, item.currencyCode) }} Gift
                Card for
                {{
                  $formatPoints(
                    calculatePoints(item.faceValue, item.currencyCode)
                  )
                }}
              </option>
            </select>
            <div class="invalid-feedback">{{ warning }}</div>
          </div>
        </template>

        <button
          :class="['btn', 'btn-block', 'btn-green', { loading: loading }]"
          :disabled="!allow_redemption"
          @click="redeemGiftCard"
        >
          Redeem for <strong>{{ $formatPoints(point_value) }}</strong>
        </button>
      </div>
    </div>

    <div class="row description-row">
      <div class="col-md-9" v-html="giftCard.description"></div>
    </div>
  </section>
</template>

<script>
import getSymbolFromCurrency from 'currency-symbol-map';

export default {
  props: ['giftCard'],
  computed: {
    team() {
      return this.$store.state.team;
    },
    variant() {
      return this.giftCard.variants.find((gc) => gc.utid === this.item_id);
    },
    currency_symbol() {
      return getSymbolFromCurrency(this.giftCard.variants[0].currencyCode);
    },
    currency_rates() {
      return this.$store.state.currency_rates;
    },
    raw_currency() {
      const currency = this.giftCard.variants[0].currencyCode;
      const rate = this.currency_rates[currency];

      const amount = Math.ceil(this.point_value * 0.01 * rate);

      return amount;
    },
    enough_points() {
      return this.$store.state.user.redeemable_points >= this.point_value;
    },
    allow_redemption() {
      const currencyAmt = this.raw_currency;

      if (!this.enough_points) return false;
      if (
        this.giftCard.cost == 'VARIABLE_VALUE' &&
        currencyAmt < this.giftCard.variants[0].minValue
      )
        return false;
      if (
        this.giftCard.cost == 'VARIABLE_VALUE' &&
        currencyAmt > this.giftCard.variants[0].maxValue
      )
        return false;
      if (currencyAmt < 1) return false;

      return true;
    },
    warning() {
      const currencyAmt = this.raw_currency;

      if (
        this.giftCard.cost == 'VARIABLE_VALUE' &&
        currencyAmt < this.giftCard.variants[0].minValue
      ) {
        return `Gift card must be at least ${this.$formatCurrency(
          this.giftCard.variants[0].minValue,
          this.giftCard.variants[0].currencyCode
        )}`;
      }

      if (currencyAmt < 1) {
        return `Gift card must be at least ${this.currency_symbol}1`;
      }

      if (
        this.giftCard.cost == 'VARIABLE_VALUE' &&
        currencyAmt > this.giftCard.variants[0].maxValue
      ) {
        return `Gift card can't be more than ${this.$formatCurrency(
          this.giftCard.variants[0].maxValue,
          this.giftCard.variants[0].currencyCode
        )}`;
      }

      if (!this.enough_points) {
        return `You don't have enough ${this.team.points_name}.`;
      }

      return null;
    },
  },
  watch: {
    item_id() {
      this.updateFixedToPoints();
    },
    currency_value(new_val) {
      if (new_val.toString().indexOf('.') > -1) {
        this.currency_value = parseInt(new_val.toString().split('.')[0]);
      } else {
        this.currency_value = parseInt(new_val) || '';
      }

      const currency = parseInt(new_val) || 0;
      this.point_value = this.calculatePoints(
        currency,
        this.giftCard.variants[0].currencyCode
      );
    },
  },
  data() {
    return {
      point_value: 100,
      error: null,
      item_id: '',
      currency_value: 0,
      loading: false,
    };
  },
  mounted() {
    this.mountGiftCard();
  },
  methods: {
    async redeemGiftCard() {
      this.loading = true;
      const resp = await this.$api.RewardPortal.GiftCards.redeem(
        this.giftCard.id,
        this.item_id,
        this.point_value
      );
      this.loading = false;

      if (!!resp.error) {
        if (resp.error === 'INSUFFICIENT_FUNDS') {
          this.$toast.error(
            'Gift card redemptions are temporarily unavailable, an administrator has been notified.'
          );
        }
        if (resp.error === 'INSUFFICIENT_POINTS') {
          this.$toast.error(
            `You don't have enough ${this.team.points_name} for this redemption.`
          );
        }

        this.$toast.error('An unknown error has occured, please try again.');
        return;
      }

      if (resp.success === true) {
        this.$store.dispatch(
          'adjustRedeemablePoints',
          -Math.abs(this.point_value)
        );
        //this.$toast.success('Your gift card redemption is processing.')
        this.$router.push({
          name: 'rewards_redemptions',
          params: {
            redemption_id: resp.redemption_id,
          },
        });
      }
    },
    mountGiftCard() {
      if (this.giftCard.cost != 'VARIABLE_VALUE') {
        this.item_id = this.giftCard.variants[0].utid;
      } else {
        this.currency_value =
          Math.floor(this.giftCard.variants[0].minValue) > 0
            ? this.giftCard.variants[0].minValue
            : 1;
      }
    },
    updateFixedToPoints() {
      if (this.giftCard.cost == 'VARIABLE_VALUE') return false;

      const variant = _.find(this.giftCard.variants, { utid: this.item_id });
      this.point_value = this.calculatePoints(
        variant.faceValue,
        variant.currencyCode
      );
    },
    calculatePoints(face_value, currency = '') {
      //convert to currency
      if (!currency) {
        const variant = _.find(this.giftCard.variants, { utid: this.item_id });
        currency = variant.currencyCode;
      }

      const currency_rate = this.currency_rates[currency];
      face_value = face_value / currency_rate;
      return Math.ceil(face_value / 0.01);
    },
  },
};
</script>
